/* General Styles */
.user-from-profile {
    font-family: 'Poppins', sans-serif;
    
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  /* Profile Container */
  .profile-container {
    width: 200%;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    padding: 30px;
    animation: fadeIn 0.8s ease-in-out;
  }
  
  /* Header */
  .profile-header {
    text-align: center;
    margin-bottom: 25px;
  }
  
  .profile-header h2 {
    font-size: 28px;
    font-weight: 700;
    color: #333;
  }
  
  /* Profile Table */
  .profile-table {
    margin-bottom: 20px;
  }
  
  /* Profile Row with Two Columns */
  .profile-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 15px;
  }
  
  .profile-column {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  /* Input Styling */
  .input-label {
    font-weight: 600;
    margin-bottom: 5px;
    color: #555;
  }
  
  .input-box {
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    width: 100%;
    transition: border-color 0.3s;
    background-color: #f9f9f9;
  }
  
  .input-box:focus {
    outline: none;
    border-color: #333;
  }
  
  /* Input Wrapper with Edit Button */
  /* Input Wrapper with Flexbox Alignment */
.input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

/* Input Box Styles */
.input-box {
  flex: 1; /* Take remaining space */
  padding: 10px 40px 10px 15px; /* Leave space for the edit button */
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  background-color: #f9f9f9;
  transition: border-color 0.3s;
}

.input-box:focus {
  outline: none;
  border-color: #333;
}

/* Edit Button Styles */
.edit-btn {
  position: absolute;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 18px;
  color: #007bff;
  cursor: pointer;
  transition: transform 0.2s;
}

.edit-btn:hover {
  transform: scale(1.2);
}

  
  /* Action Section */
  .action-section {
    border-top: 2px solid #e0e0e0;
    padding-top: 15px;
  }
  
  .action-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }
  
  .action-btn {
    background-color:transparent;
    color: #007bff;
    border: 1px solid #007bff;
    border-radius: 8px;
    padding: 4px 12px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
  
}

/* Hover Effect */
.action-btn:hover {
    background-color: #0056b3;
    border-color: white;
    color: white;
    transform: translateY(-2px); /* Subtle lift effect */
   
}

/* Active/Pressed State */
.action-btn:active {
    transform: translateY(0); /* Reset lift */
   
    background-color: #004494;
}

/* Focus State for Accessibility */
.action-btn:focus {
    outline: none;
    /* box-shadow: 0 0 8px rgba(0, 123, 255, 0.6); */
}

  
  /* Fade-in Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .profile-row {
      flex-direction: column;
      gap: 10px;
    }
  
   
  
    .action-btn {
     font-size: 16px;
    } 
    .profile-container{
      width: fit-content;
      margin-top: 80px;
      margin-bottom: 100px;
    }
  }
  
  @media (max-width: 480px) {
    .profile-header h2 {
      font-size: 24px;
    }
  
    .input-box {
      font-size: 14px;
    }
  
    .edit-btn {
      font-size: 16px;
    }
  }