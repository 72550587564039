/* Offcanvas Styles */
.offcanvas {
    transition: transform 0.3s ease-in-out;
}

.offcanvas.show {
    transform: translateX(0);
}

.offcanvas-end {
    right: 0;
}

/* Background */
.bg-theme {
    background-color: #f8f9fa; /* Light background */
}
/* Base styles for the offcanvas */
#DepositSelectMethod {
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    animation: slideIn 0.5s ease-in-out;
    overflow-y: auto;
  }
  
  #DepositSelectMethod .content {
    width: 100%;
    max-width: 500px;
    background:  rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    animation: fadeInUp 0.6s ease-out;
  }
  
  #DepositSelectMethod .d-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  #DepositSelectMethod .list-group-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border: none;
    background-color: #f8f9fa;
    border-radius: 8px;
    margin-bottom: 10px;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  #DepositSelectMethod .list-group-item:hover {
    transform: translateY(-2px);
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  #DepositSelectMethod .has-bg {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #DepositSelectMethod .eWallet {
    font-weight: 600;
    color: #333;
  }
  
  .icon-m {
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .icon-m:hover {
    color: #d9534f;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    #DepositSelectMethod .content {
      max-width: 90%;
    }
  
    #DepositSelectMethod .list-group-item {
      font-size: 14px;
    }
  }
  
  /* Animations */
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
/* Responsive Design */
@media (max-width: 768px) {
    .font-22 {
        font-size: 18px; /* Smaller title font size for mobile */
    }

    .list-group-item {
        padding: 10px; /* Less padding on mobile */
    }

    .content {
        padding: 15px; /* Less padding on mobile */
    }
}

