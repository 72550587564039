/* Off-canvas container with a clean and modern look */
#BankAccounts {
    background-color: #f4f7f6;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Header Section */
#BankAccounts .content .d-flex {
    align-items: center;
    margin-bottom: 20px;
}

#BankAccounts .content .Title {
    font-weight: bold;
    font-size: 22px;
    color: #333;
    text-transform: uppercase;
    margin-left: 10px;
}

/* Close Icon */
#BankAccounts .content .icon-m i {
    color: #FF4B23;
    font-size: 24px;
    cursor: pointer;
    transition: color 0.3s ease;
}

#BankAccounts .content .icon-m i:hover {
    color: #e03e19;
}

/* Style for each form row */
.form-custom12 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    padding: 12px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: box-shadow 0.3s ease;
}

/* Hover effect for form row */
.form-custom12:hover {
    box-shadow: 0 4px 15px rgba(0, 123, 255, 0.2);
}

/* Left side text */
.form-custom12 .BankAccountNames {
    font-size: 14px;
    color: #6c757d;
    font-weight: 600;
    text-align: left;
    margin-left: 0px !important;
}

/* Right side data */
.form-custom12 .BankAccountNames2 {
    font-size: 16px;
    
    color: black;
    text-align: right;
    padding-left: 10px;
    transition: color 0.3s ease;
    font-weight: 700;
}

/* Hover effect on right side data */
.form-custom12 .BankAccountNames2:hover {
    color: #0056b3;
}

/* Box-shadow and spacing for modal input fields */
.user-box input {
    width: 100%;
    padding: 12px 20px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #f8f9fa;
    font-size: 16px;
    color: #333;
    margin-bottom: 15px;
    transition: border-color 0.3s ease;
}

.user-box input:focus {
    border-color: #007bff;
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.2);
}

.user-box label {
    font-size: 14px;
    color: #888;
    transition: color 0.3s ease;
}

/* Button Styling */
.btn-full {
    background-color: #888;
    color: white;
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 6px;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-full:hover {
    background-color: #888;
    transform: scale(1.05);
}

/* Button for Add Bank Account */
#withdraw_btn_bank {
    background-color: #007bff;
    color: white;
    padding: 14px;
    font-size: 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

#withdraw_btn_bank:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .form-custom12 {
        flex-direction: column;
        align-items: flex-start;
    }

    .form-custom12 .BankAccountNames2 {
        text-align: left;
        padding-left: 0;
    }
    
    .Title {
        font-size: 18px;
    }
}
/* Main Container */
#BankTransaction {
    width: 100%;
    max-width: 500px;
    margin: auto;
    padding: 20px;
    background-color: #fff; 
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); 
    animation: fadeIn 0.5s ease-in-out;
  }
  
  /* Fade-in animation */
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  .transaction-table{
    width: 100%;
    max-width: 500px;
    margin: auto;
    padding: 20px;
    background-color: #fff; /* White background for the box */
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Box shadow */
    animation: fadeIn 0.5s ease-in-out;
  }
  
  /* Title Section */
  .title-section {
    font-size: 1.5rem;
    font-weight: 700;
    color: #6c757d;
     /* Green background for Deposit */
    padding: 10px 15px;
    border-bottom: 4px solid #ddd; /* Bottom border for Deposit section */
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .border-row{
    border-bottom: 2px solid #ddd;
  }
  /* Style for the Deposit label */
.label-deposit {
    color: white;
    background-color: #6c757d;
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-block;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .label-deposit:hover {
    background-color: white;
    color: #6c757d;  /* Darken on hover */
    transform: scale(1.05);  /* Slightly enlarge on hover */
  }
  .history1{
    border-bottom: 4px solid #ddd; 
  }
  
  
  /* Table Styling */
  .transaction-table {
    width: 100%;
    margin-top: 15px;
  }
  
  .transaction-table tbody tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
  }
  
  .transaction-table tbody tr:last-child {
    border-bottom: none;
  }
  
  .transaction-table th, .transaction-table td {
    font-weight: bold;
    font-size: 1rem;
  }
  
  /* Transaction Status Colors */
  .transactionStatus .pending {
    color: red;
    font-weight: 700;
  }
  
  .transactionStatus .approved {
    color: green;
    font-weight: 700;
  }
  
  /* Left and Right Column Styling */
  .transaction-row {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
  }
  
  .transaction-row .label {
    color: #6c757d;
    font-weight: 500;
  }
  
  .transaction-row .value {
    font-weight: 700;
    text-align: right;
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    #BankTransaction {
      padding: 10px;
    }
  
    .title-section {
      font-size: 1.2rem;
    }
  
    .transaction-table th, .transaction-table td {
      font-size: 0.9rem;
    }
  }
  