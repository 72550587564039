/* Styling for the modal and form */
.modal-header3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    color: #444;
    padding: 10px 15px;
    border-bottom: 1px solid #444;
}

.close-btn {
    background: none;
    border: none;
    color: #444;
    font-size: 30px;
    cursor: pointer;
}



.user-box3 {
    position: relative;
    margin-bottom: 20px;
    width: 100%;
}

.user-box3 input {
    width: 100%;
    padding: 10px;
    background: transparent;
    border: none;
    border-bottom: 1px solid #ccc;
    outline: none;
    color: #495057;
    font-size: 16px;
    transition: 0.3s;
    
}

.user-box3 input:focus {
    border-bottom: 2px solid #333;
}

.user-box3 label {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #999;
    font-size: 16px;
    pointer-events: none;
    transition: 0.3s;
    margin-top: 5px;

}

.user-box3 input:focus ~ label,
.user-box3 input:not(:placeholder-shown) ~ label {
    top: -20px;
    left: 0;
    color:  #333;
    font-size: 12px;
}

.modal-body-custom3 {
    padding: 30px;
    background-color: #f8f9fa;
    border-radius: 0 0 5px 5px;
}
.submit-button {
    text-align: center;
}


button.submit-btn5 {
    margin-top: 15px;
    width: 100%; /* Full width for better responsiveness */
    max-width: 250px;
    border-radius: 25px;
    padding: 12px 0;
    background-color: rgba(0, 0, 0, 0.6); /* Transparent black */
    border: none;
    color: white;
    transition: background-color 0.3s;
    font-size: 16px;
    cursor: pointer;
}

button.submit-btn5:hover {
    background-color: rgba(116, 111, 111, 0.8);
}
