/* Base styles for the offcanvas */
#TransferCredit {
    display: flex;
    justify-content: center;
    align-items: center; /* Start from the bottom */
    height: 100%;
    backdrop-filter: blur(10px);
    
    overflow-y: auto;
  }
  
  #TransferCredit .content {
    width: 100%;
    max-width: 500px;
    background: #ffffff;
    border-radius: 16px 16px 0 0;
    padding: 20px;
    box-shadow: 0 -4px 15px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.6s ease-out;

    
  }
  
  /* Form box styling */
  .TransferCreditFormBox {
    background: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    padding: 20px;
    margin-bottom: 10px;
  }
  
  .formbold-input-group {
    margin-bottom: 15px;
  }
  
  .formbold-form-label {
    font-weight: 600;
    color: #555;
    margin-bottom: 5px;
    display: block;
  }
  
  .formbold-form-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .formbold-form-input:focus {
    border-color: #4caf50; /* Light green border on focus */
  }
  
  .pin-input-box {
    width: 40px;
    height: 40px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-left: 5px;
    text-align: center;
    font-size: 18px;
    transition: border-color 0.3s;
  }
  
  .pin-input-box:focus {
    border-color: #4caf50;
  }
  
  .btn {
    border: none;
    border-radius: 8px;
    background: linear-gradient(90deg, #28a745, #218838);
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .btn:hover {
    background: linear-gradient(90deg, #218838, #1e7e34);
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    #TransferCredit .content {
      max-width: 90%;
    }
  
    .formbold-input-group {
      margin-bottom: 10px;
    }
  
    .btn {
      font-size: 14px;
    }
  }
  
  /* Animations */
  @keyframes openToCenter {
    0% {
      transform: translateY(100%); /* Start from the bottom */
      opacity: 0;
    }
    60% {
      transform: translateY(20%); /* Smoothly move up */
      opacity: 0.7;
    }
    100% {
      transform: translateY(0); /* Center */
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  