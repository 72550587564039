/* Full-screen backdrop to center the modal */
.invitation-modal-backdrop6 {
    
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:#e9ecef; /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  /* Centered modal container */
  .invitation-modal6 {
    background-color: #fff;
    border-radius: 12px;
    width: 90%;
    max-width: 400px;
    padding: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    animation: slideUp 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Header styling */
  .modal-header6 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 1px solid #888;
  }
  button#copy_link{
    background: white !important;
    color: #888;
  }
 
 
  button#copy_link:hover{
    color: white;
    background: #888 !important;
  }
  
  .title-container6 h5 {
    font-size: 12px;
    color: #888;
    text-transform: uppercase;
    margin-bottom: 5px;
   
  }
  
  .modal-title6 {
    font-size: 24px;
    font-weight: bold;
  }
  
  /* Close button */
  .close-btn6 {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #888;
  }
  .close-btn6:hover{
    color: #0c0c0c;
  }
  
  /* QR Image */
  .qr-image {
    width: 100%;
    max-width: 250px;
    margin: 15px 0;
  }
  
  /* Input styling */
  .form-custom {
    width: 100%;
    margin-bottom: 15px;
  }
  
  .invite-link {
    width: 100%;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 8px;
    font-size: 14px;
    background-color: #f9f9f9;
  }
  
  /* Button row */
  .button-row {
    display: flex;
    width: 100%;
    gap: 10px;
    justify-content: space-between;
  }
  
  .button-row {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin: 20px 0;
     /* Makes buttons stack on small screens */
  }
  
  .btn6, .btn {
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background-color: #888;
    border: none;
    border-radius: 8px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    min-width: 180px; /* Ensures consistent button width */
  }
  
  .btn6:hover, .btn:hover {
    background-color:white ;
    color: #888;
  }
  
  .btn6:active, .btn:active {
    background-color:#333;
  }
  
  #copy_link {
    background-color: white;
    color: #888;
  }
  
  #copy_link:hover {
    background-color: #888;
    color: white;
  }
  
  @media (max-width: 600px) {
    .button-row {
      flex-direction: column;
      align-items: center;
    }
  
    .btn6, .btn {
      width: 100%; /* Full width on small screens */
    }
  }
  
  
  /* Animations */
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .invitation-modal {
      width: 95%;
      max-width: none;
    }
  }
  