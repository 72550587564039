.form-custom {
    position: relative;
    width: 100%; /* Full width for form-custom div */
}

.form-control {
    width: 100%; /* Full width for input */
    padding-right: 40px; /* Space for loader icon */
    box-sizing: border-box; /* Ensure padding is included in total width */
}

.position-absolute {
    right: 10px; /* Space from the right edge */
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* Prevent interaction with the icon */
}

.row {
    margin-left: 0; /* Prevent default margin from Bootstrap */
    margin-right: 0;
}

.container-fluid {
    padding-left: 15px; /* Adjust for left padding on small screens */
    padding-right: 15px; /* Adjust for right padding on small screens */
}

@media (max-width: 576px) {
    .form-control {
        padding-right: 35px; /* Adjust padding on smaller screens if needed */
    }

    .position-absolute {
        right: 8px; /* Adjust right positioning on small screens */
    }
}
