/* Blur background when the modal is open */
.change-email-modal {
    backdrop-filter: blur(12px); /* Stronger blur */
    -webkit-backdrop-filter: blur(12px);
    background-color: rgba(0, 0, 0, 0.4); /* Transparent dark overlay */
}

/* Centering the modal vertically and horizontally */
.modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
   
}
.login-box1{
    /* width: 80% !important; */
}

/* Modal content styling */


/* Animation for the modal */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Header styling */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

/* Title text styling */
.header .title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    font-family: 'Poppins', sans-serif;
}

/* Close button styling */
.close-btn {
    background-color: transparent;
    border: none;
    font-size: 30px;
    color: #333;
    cursor: pointer;
    transition: transform 0.2s ease, color 0.3s ease;
    font-family: 'Poppins', sans-serif;
}

.close-btn:hover {
     /* Red hover color */
    transform: scale(1.2); /* Zoom effect */
}

/* Form input styling */
.user-box {
    position: relative;
    margin-bottom: 20px;
}

.user-box input {
    width: 100%;
    padding: 10px;
    background: transparent;
    border: none;
    border-bottom: 2px solid #aaa;
    outline: none;
    color: #333;
    font-size: 16px;
    transition: border-color 0.3s;
    font-family: 'Poppins', sans-serif;
}

.user-box input:focus {
    border-color: #333; /* Blue on focus */
}
.modal-content{
    width: 100% !important;
}

.user-box label {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    transition: 0.3s ease;
    color: #aaa;
    font-family: 'Poppins', sans-serif;
}

/* Shrink label when input is focused or filled */
.user-box input:focus + label,
.user-box input:not(:placeholder-shown) + label {
    top: -15px;
    font-size: 12px;
    color: #333;
}

/* Submit button styles */
.submit-button {
    text-align: center;
}

button.btn-submit1 {
    margin-top: 15px;
    width: 100%; /* Full width for better responsiveness */
    max-width: 250px;
    border-radius: 25px;
    padding: 12px 0;
    background-color: rgba(0, 0, 0, 0.6); /* Transparent black */
    border: none;
    color: white;
    transition: background-color 0.3s;
    font-size: 16px;
    cursor: pointer;
}

button.btn-submit1:hover {
    background-color: rgba(116, 111, 111, 0.8);
}

/* Toast notifications styling */
.Toastify__toast {
    font-family: 'Poppins', sans-serif;
}

/* Responsive design for different screen sizes */
@media (max-width: 768px) {
    .modal-dialog {
        height: auto;
        margin: 20px;
    }

    .modal-content {
        padding: 20px;
    }

    .header .title {
        font-size: 20px;
    }

    .close-btn {
        font-size: 22px;
    }
}

@media (max-width: 480px) {
    .modal-content {
        padding: 15px;
    }

    .header .title {
        font-size: 18px;
    }

    .close-btn {
        font-size: 20px;
    }

    .btn-submit1 {
        width: 100%; /* Full width for small screens */
    }
}
